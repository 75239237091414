<template>
  <div class="dashboard">
    <div class="dash-menu">
      <div class="container">
        <div class="row">
          <div class="col-12 text-right">
            <router-link :to="{ name: 'Tiedottaminen', query: { id: 'new' } }"><button class="btn btn-new d-inline-block"><i class="fas fa-plus"></i> Luo uusi tiedote</button></router-link>
            <router-link :to="{ name: 'Huoltoilmoitukset', query: { id: 'new' } }"><button class="btn btn-new d-inline-block"><i class="fas fa-plus"></i> Luo uusi huoltotyö</button></router-link>
            <router-link :to="{ name: 'Asukkaat', query: { id: 'new' } }"><button class="btn btn-new d-inline-block"><i class="fas fa-plus"></i> Luo uusi asukas</button></router-link>
          </div>
        </div>
      </div>
    </div>
    <b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 pt-5 pb-4">
          <GChart type="ColumnChart" :data="data.totals" :options="totalOptions" />
        </div>
        <div class="col-12 col-lg-6 pb-4">
          <div class="dashicons">
            <div class="icon">
              <i class="fas fa-bell"></i>
              <p>Uusia huoltoilmoituksia</p>
              <span>{{ data.new_tickets }}</span>
            </div>
            <div class="icon">
              <i class="fas fa-briefcase"></i>
              <p>Keskeneräisiä huoltotöitä</p>
              <span>{{ data.unfinished_tickets }}</span>
            </div>
            <div class="icon">
              <i class="fas fa-business-time"></i>
              <p>Keskim. huoltotyön valmistumisaika (3kk)</p>
              <span>{{ data.ticket_time }} pvä</span>
            </div>
            <div class="icon">
              <i class="fas fa-user-lock"></i>
              <p>Uusia asukkaita (ei aktiivisia)</p>
              <span>{{ data.new_users }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 pb-4">
          <h2>Uusimmat huoltoilmoitukset</h2>
          <table class="admin-list">
            <tr v-for="(item) in tickets" :key="item.id" :id="['ticket-' + item.id]">
              <td class="w120"><p><span class="date">{{ item.created | formatDate }}</span><span class="status" :class="[ 'status-' + item.status ]">{{ getStatus(item.status) }}</span></p></td>
              <td class="text"><p><router-link :to="{ name: 'Asukkaat', query: { id: item.created_by } }">{{ item.site_resident }}</router-link><br/><strong><router-link :to="{ name: 'Kohteet', query: { id: item.site_id } }">{{ item.site_name }}</router-link></strong></p></td>
              <td class="text"><p><router-link :to="{ name: 'Huoltoilmoitukset', query: { id: item.id } }">{{ item.title }}</router-link></p></td>
            </tr>
          </table>
        </div>
        <div class="col-12 col-lg-6 pb-4">
          <h2>Viimeisimmät tiedotteet</h2>
          <table class="admin-list">
            <tr v-for="(item) in bulletins" :key="item.id" :id="['bulletin-' + item.id]">
              <td class="w120"><p><span class="date">{{ item.created | formatDate }}</span></p></td>
              <td class="text"><p>{{ item.name }}</p></td>
              <td class="text-right"><p><i class="fas fa-at" v-if="item.send_email"></i> <i class="fas fa-sms" v-if="item.send_sms"></i> <i class="fas fa-laptop" v-if="item.send_channel"></i></p></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GChart } from 'vue-google-charts'

export default {
  components: {
    GChart
  },
  data: function () {
		return {
			loading: true,
      error: false,
      statuses: [ { id: 1, name: 'Uusi' }, { id: 2, name: 'Kesken' }, { id: 3, name: 'Valmis' }, { id: 4, name: 'Toistuva' } ],
			bulletins: [],
			tickets: [],
      data: { totals: [ [ 'Kuukausi', 'Kpl' ], [ 0, 0 ] ] },
      totalOptions: { title: 'Huoltoilmoitukset viim. 6kk', height: 300 }
		}
	},
	created: function () {
		var vm = this;

    this.axios.get('/api/dashboard')
		.then(function (response) {
			if(response.status == 200) {
				vm.data = response.data.object;
			}
		})
		.catch(function (error) {
			console.log(error);
		})

    vm.loadTickets();
    vm.loadBulletins();
	},
	methods: {
		loadBulletins: function () {
			var vm = this;
			vm.loading = true;
			this.axios.get('/api/bulletins?limit=5')
			.then(function (response) {
				if(response.status == 200) {
					vm.loading = false;
					vm.bulletins = response.data.results;
				} else {
          vm.error = response.data.message;
          vm.bulletins = [];
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.bulletins = [];
				vm.loading = false;
			})
		},
    loadTickets: function () {
			var vm = this;
			vm.loading = true;
			this.axios.get('/api/tickets?limit=5')
			.then(function (response) {
				if(response.status == 200) {
					vm.loading = false;
					vm.tickets = response.data.results;
				} else {
          vm.error = response.data.message;
          vm.tickets = [];
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.tickets = [];
				vm.loading = false;
			})
		},
    getStatus: function(s_id) {
      if(s_id != null) {
        var vm = this;
        var find = parseInt(s_id);
        const result = vm.statuses.find( ({ id }) => id === find );
        if(result) return result.name;
      }
      return s_id;
    },
  }
}
</script>
